import React, { useContext } from "react";
import "./Services.css";
import { Header } from "../../components/Header/Header.js";
import { Footer } from "../../components/Footer/Footer.js";
import { ServicesContext } from "../../Contexts/ServicesContext";
import { DeltaContext } from "../../Contexts/DeltaContext.js";
import { NavLink, useNavigate } from "react-router-dom";

function Services() {
  const { services } = useContext(ServicesContext);
  const {
    selectedContent,
    setSelectedOption,
    Index,
    setShowSubMenu,
    setShowOptionMenu,
    setShowMobileMenu,
    setSelectedItem,
    setSelectedContent,
  } = useContext(DeltaContext);
  const navigate = useNavigate();
  let main = {};
  if (selectedContent !== "") {
    main = services.find(
      (item) => item.title.toLowerCase() === selectedContent.toLowerCase()
    );
  } else {
    main = services.find(
      (item) => item.title.toLowerCase() === "auditoría financiera"
    );
  }
  return (
    <div className="servicesScreen Screen">
      <Header />
      <section className="servMainSection">
        <div className="textContainer">
          <h1>{main.title}</h1>
          <h3>Delta Consult ofrece:</h3>
          <p>{main.ofrece[0]}</p>
          <h3>Nuestra experiencia:</h3>
          <p>{main.experiencia[0]}</p>
        </div>
        <div className="imageContainer">
          <img alt={main.title} src={main.imagen[Index]} />
        </div>
      </section>
      <section
        className="servTeamSection Bottom"
        style={{ marginTop: "100px" }}
      >
        <h2>Nuestro equipo especializado</h2>
        <div className="serviceTeamGrid">
          {main.equipo.map((item, index) => (
            <div
              key={`equipoEspecializado${index}`}
              onClick={() => {
                setShowSubMenu(false);
                setShowOptionMenu(false);
                setShowMobileMenu(false);
                setSelectedItem("");
                setSelectedContent("");
                navigate("/Nosotros");
                setTimeout(function () {
                  const element = document.getElementById("NuestroEquipo");
                  if (element) {
                    element.scrollIntoView({
                      behavior: "auto",
                      block: "center",
                      inline: "center",
                    });
                  }
                }, 50);
                setSelectedOption("NOSOTROS");
              }}
            >
              <div className="text">
                <p className="name">{item.nombre}</p>
                <p className="role">{item.rol}</p>
              </div>
              <img alt="foto" src={item.foto} />
            </div>
          ))}
        </div>
        <NavLink
          className="serviceLink botonComun"
          onClick={() => {
            setTimeout(function () {
              const element = document.getElementById("FormularioContacto");
              if (element) {
                element.scrollIntoView({
                  behavior: "auto",
                  block: "center",
                  inline: "center",
                });
              }
            }, 50);
            setSelectedOption("CONTACTO");
          }}
          to={"/Contacto"}
        >
          <p>Contáctanos para más informacion</p>
        </NavLink>
      </section>
      <Footer />
    </div>
  );
}

export { Services };
