import React from "react";

function Rights() {
  return (
    <div className="derechos">
      <p>
        Sitio propiedad de Delta Consult por BlindTrust Software Development en
        2024
      </p>
    </div>
  );
}

export { Rights };
